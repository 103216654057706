import React from "react"

import "../components/Hero/styles.css"
import notFound from "../assets/404.png"
import { Helmet } from "react-helmet"
export default () => {
  return (
    <>
      <Helmet>
        <title>Página 404</title>
      </Helmet>
      <div className="containerNotFound">
        <div className="parent">
          <h1 className="notFoundTitle">ERROR 404</h1>
          <h2 className="notFoundSecondTitle">No encontramos esta página</h2>
          <img
            className="notFoundImage"
            src={notFound}
            alt="Página no encontrada"
          />
          <br />
          <a className="notFoundLink" href="/">
            Volver al inicio
          </a>
        </div>
      </div>
    </>
  )
}
